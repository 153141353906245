import React, { useState } from "react";
//= Layout
import MainLayout from "layouts/Main";
//= Components
import Navbar from "components/Navbars/ITCreativeNav";
import ModalVideo from "react-modal-video";
import ChatBanner from "components/Software/ChatBanner";
import Footer from "components/Saas/Footer";
import { Link } from "gatsby";
import Blog8 from "components/Software/Blog8";


const FixDeposit = () => {

  const [isOpen, setOpen] = useState(false);

  const openVideo = (e) => {
    e.preventDefault();
    setOpen(true);
  }
  return (
    <MainLayout>
      <Navbar />
      <header className="style-3 overflow-hidden" data-scroll-index="0" style={{background: '#6c757d'}}>
      <div className="container">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-5">
              <div className="info" style={{ background: '#00000000'  }}>
                <h1 className="h1">Grow Your Wealth  <span> with Us </span></h1>
                <p className="p">Secure your future with our competitive fixed deposit rates </p>                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img ">       
        <img src="/assets/img/about/style_3_1.png" alt="" />   
        <img
                    src="/assets/img/about/INVESTMENT (2).png"
                    alt=""
                    className="img-body "
                  />
        
      </div>
    </header>
      <main>
        <section
          className="about section-padding style-3"
          data-scroll-index="3"
        >
          <div className="container">
            <div className="row gx-0 justify-content-end">
              <div className="col-lg-6">
                <div className="img  ">
                  <img
                    src="/assets/img/about/INVESTMENT (2).png"
                    alt=""
                    className="img-body "
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="">
                  <div className="section-head long-shape mb-40 style-3">
                    <h3>Fixed Deposit </h3>
                  </div>
                  <p>
                  Achieve financial stability with our Fixed Deposit options, providing you with a secure, high-yield investment tailored to meet your savings goals. Our fixed deposit accounts offer competitive interest rates, flexible terms, and guaranteed returns, allowing you to grow your wealth confidently and safely. Choose a term that suits your financial plan, and watch your funds grow with peace of mind.
                  </p>
                  <div className="text mb-20 mt-20">
                    <h5>Requirements: </h5>
                    <ul>
                      <li>Valid Identification (National ID, Driver’s License, or Passport)</li>
                      <li>Bank Verification Number (BVN)</li>
                      <li>Completed KYC verification</li>
                      <li>Proof of income</li>
                    </ul>
                  </div>
                  <Link
                    to="/contact"
                    className="btn rounded-pill bg-blue2 sm-butn text-white mt-10"
                  >
                    <span>Open a Fixed Deposit</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Blog8 />
        <ChatBanner />
      </main>
      <Footer />
    </MainLayout>
  );
};

export const Head = () => {
  return (
    <>
      <title> Invest in Our High-Yield Fixed Deposits Options</title>
<meta name="description" content=" Looking for a reliable investment option? Invest in Our High-Yield Fixed Deposits with attractive interest rates and minimal risk. Grow your funds with us" />

      <link rel="stylesheet" href="/assets/css/lib/bootstrap.min.css" />
      <link rel="stylesheet" href="/assets/css/style.css" />
    </>
  );
};

export default FixDeposit;
